import localeHome from './en-US/home.json';
import localeAbout from './en-US/about.json';
import localeManufacture from './en-US/manufacture.json';
import localeESG from './en-US/ESG.json';
import localeJoinUs from './en-US/joinUs.json';
import localePolicy from './en-US/policy.json';


export default {
  "screen.tabs.home": "Index",
  "screen.tabs.about": "About",
  "screen.tabs.products": "Products",
  "screen.tabs.news": "News",
  "screen.tabs.joinUs": "Join Us",
  "screen.tabs.contact": "Contact Us",
  "screen.tabs.manufacturing": "R&D and Manufacturing",

  "screen.btn": "Know more",

  "screen.footer.product1": "Artificial Graphite",
  "screen.footer.product2": "Natural Graphite",
  "screen.footer.product3": "Silicon Anode",
  "screen.footer.product4": "Hard Carbon Anode Materials",

  "screen.footer.manufacturing1": "Research Institute",
  "screen.footer.manufacturing2": "Manufacturing",

  "screen.footer.about1": "Join Us",
  "screen.footer.about2": "Contact Us",

  "screen.footer.news1": "ESG",
  "screen.footer.bottom1": "Privacy Policy",
  "screen.footer.bottom2": "Legal Explanation",



  "search.details.null":"Sorry, there are no results matching your search content!",
  "search.details.news":"News",
  "search.details.product":"Product",
  "search.details.screen":"Filter categories.",
  "search.details.filter":"Search results.",
  "search.text":"Please enter search content",

  "product.list.title1":"Initial capacity",
  "product.list.title2":"Initial efficiency",
  "product.list.title3":"Particle size D50",

  "popup.item1":"Capacity",
  "popup.item2":"First Efficiency",
  "popup.item3":"Particle size",
  "popup.item4":"Compaction Density",
  "popup.item5":"Fast Charging",
  "popup.item6":"The physical property index displayed on this page is a typical value, for reference only, not as a supplement to the transaction contract. The specifications to be determined separately shall prevail in the transaction between the two parties.",

  "news.prev": "Previous",
  "news.next": "Next",
  "popup.content": "Clicking the 'I Accept' button or further using this website indicates your agreement to use Cookies and grants us the right to collect and process your personal data related to your activities on this website. This information is used to optimize your visit experience. For more information on privacy policies and information security, please visit this link to read more.",
  "popup.content.btn1": "Cookie政策",
  "popup.content.btn2": "Yes",

  "contact.title": "Contact Us",
  "contact.content.title": "Zichen Technology Shanghai Headquarters",
  "contact.content.yx": "zcpublic@jxzichen.com",
  "contact.content.dh": "021-61902916",
  "contact.content.dz": "No. 116-117, Chuangyan Zhizao, Lane 3188, Xiupu Road, Pudong New Area, Shanghai",
  "esg.responsible": "Responsible supply chain policies.",

  ...localeHome,
  ...localeAbout,
  ...localeManufacture,
  ...localeESG,
  ...localeJoinUs,
  ...localePolicy
};