import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import i18n from './locale'
import axios from "axios";
Vue.prototype.$axios = axios
axios.defaults.baseURL="/api" 

import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = true
import 'lib-flexible/flexible'

Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,  // 引入国际化组件
  render: h => h(App)
}).$mount('#app')
