import localeHome from './ko-KR/home.json';
import localeAbout from './ko-KR/about.json';
import localeManufacture from './ko-KR/manufacture.json';
import localeESG from './ko-KR/ESG.json';
import localeJoinUs from './ko-KR/joinUs.json';
import localePolicy from './ko-KR/policy.json';

export default {
    "screen.tabs.home": "首页",
    "screen.tabs.about": "우리에 대해",
    "screen.tabs.products": "제품",
    "screen.tabs.news": "뉴스",
    "screen.tabs.joinUs": "인재채용",
    "screen.tabs.contact": "연락하기",
    "screen.tabs.manufacturing": "개발 및 제조",
  
    "screen.btn": "더 보기",
  
    "screen.footer.product1": "인조흑연 음극재",
    "screen.footer.product2": "천연흑연 음극재",
    "screen.footer.product3": "실리콘 음극재",
    "screen.footer.product4": "하드카본 음극재",
  
    "screen.footer.manufacturing1": "연구소",
    "screen.footer.manufacturing2": "생산 제조",
  
    "screen.footer.about1": "인재채용",
    "screen.footer.about2": "연락하기",
  
    "screen.footer.news1": "ESG",
    "screen.footer.bottom1": "개인 정보 보호 정책",
    "screen.footer.bottom2": "법률 설명",

    "search.details.null":"죄송합니다, 검색 내용과 일치하는 결과가 없습니다",
    "search.details.news":"뉴스",
    "search.details.product":"제품",
    "search.details.screen":"분류 필터링.",
    "search.details.filter":"검색 결과.",
    "search.text":"검색 내용을 입력하십시오.",
  
    "product.list.title1":"초기 용량",
    "product.list.title2":"초기 효율",
    "product.list.title3":" 입자 크기 D50",

    "popup.item1":"용량",
    "popup.item2":"초기효율",
    "popup.item3":"입도(D50)",
    "popup.item4":"압연밀도",
    "popup.item5":"급속충전",
  
    "popup.item6":"이 페이지에 표시된 물성 지표는 전형적인 값고 참고용일 뿐 거래 계약의 보충이 아니다. 쌍방이 거래할 때 별도로 결정된 사양 요구 사항이 우선한다.",

    "news.prev": "이전 가기 ",
    "news.next": "다음 가기",
  "popup.content": "이 사이트는 쿠키를 사용합니다. 사이트를 계속 탐색하면 쿠키 사용에 동의하게 됩고본 사이트에서 활동하시는 개인 데이터를 수집하고 처리할 권한을 부여합니다. 이 같은 정보는 고객의 방문 경험을 최적화하는 데 사용됩니다.프라이버시 약관과 정보보안에 관한 더 많은 정보를 원하시면, 링크를 통해 더 많은 정보를 확인하시기 바랍니다.",
  "popup.content.btn1": "Cookie政策",
  "popup.content.btn2": "Yes",


  "contact.title": "연락처",
  "contact.content.title": "자천테크 상하이 본사;",
  "contact.content.yx": "zcpublic@jxzichen.com",
  "contact.content.dh": "021-61902916",
  "contact.content.dz": "상하이시 푸둥신구 슈푸로 3188번지 창연지조 116-117호",
  "esg.responsible": "책임 있는 공급망 정책",


  ...localeHome,
  ...localeAbout,
  ...localeManufacture,
  ...localeESG,
  ...localeJoinUs,
  ...localePolicy

};
