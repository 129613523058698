import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,
    isVideoOnShow: false,
    tabShow: 1,
    I18nState: 'zh_CN', // 语言状态
    PopupTitle:'',
    searchText:''
  },
  getters: {
    isColl: state => state.isCollapse,
    isVideo: state => state.isVideoOnShow,
    isTabShow: state => state.tabShow,
    isPopupTitle: state => state.PopupTitle,
    isI18nState: state => state.I18nState,
    isSearchText: state => state.searchText
  },
  mutations: {
    Changecoll(state) {
      state.isCollapse = !state.isCollapse
    },
    ChangeIsVideo(state) {
      state.isVideoOnShow = !state.isVideoOnShow
    },
    ChangeIsTabShow(state, n) {
      state.tabShow = n
    },
    ChangePopupTitle(state, n) {
      state.PopupTitle = n
    },
    setI18nState(state,val){
      state.I18nState=val
    },
    setsearchText(state,val){
      state.searchText=val
    }
  },
  actions: {

  },
  modules: {

  }
})
