import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from './en-US';
import cn from './zh-CN';
import ja from './ja-JP';
import ko from './ko-KR';
import store from "@/store"

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale:localStorage.getItem('lang') || 'zh_CN', // 设置默认语言,
  // fallbackLocale: 'en-US',
  // legacy: false,
  // allowComposition: true,
  silentTranslationWarn: true,
  messages: {
    'en_US': en,
    'zh_CN': cn,
    'ja_JP': ja,
    'ko_KR': ko,
  },
});

export default i18n;
