import localeHome from './ja-JP/home.json';
import localeAbout from './ja-JP/about.json';
import localeManufacture from './ja-JP/manufacture.json';
import localeESG from './ja-JP/ESG.json';
import localeJoinUs from './ja-JP/joinUs.json';
import localePolicy from './ja-JP/policy.json';


export default {
    "screen.tabs.home": "首页",
    "screen.tabs.about": "紫宸について",
    "screen.tabs.products": "製品",
    "screen.tabs.news": "ニュース",
    "screen.tabs.joinUs": "採用情報",
    "screen.tabs.contact": "お問い合わせ",
    "screen.tabs.manufacturing": "R&Dと製造",
  
    "screen.btn": "もっと見る",
  
    "screen.footer.product1": "人造黒鉛負極",
    "screen.footer.product2": "天然黒鉛負極",
    "screen.footer.product3": "シリコン負極",
    "screen.footer.product4": "ハードカーボン負極",
  
    "screen.footer.manufacturing1": "研究院",
    "screen.footer.manufacturing2": "生產·製造",
  
    "screen.footer.about1": "採用情報",
    "screen.footer.about2": "お問い合わせ",
  
    "screen.footer.news1": "ESG",
    "screen.footer.bottom1": "プライバシーポリシー",
    "screen.footer.bottom2": "法律の説明",

    "search.details.null":"申し訳ありませんが、検索内容に一致する結果はありません！",
    "search.details.news":"ニュース",
    "search.details.product":"製品",
    "search.details.screen":"カテゴリーを絞り込み。",
    "search.details.filter":"検索結果。",
    "search.text":" 検索内容を入力してください。",
  
    "product.list.title1":"初期容量",
    "product.list.title2":" 初期効率",
    "product.list.title3":"粒径D50",
  
    "popup.item1":"容量",
    "popup.item2":"初期充放電効率",
    "popup.item3":"粒子径",
    "popup.item4":"錠剤密度",
    "popup.item5":"急速充電特性",

    "popup.item6":"本ページに示されている物性指標はあくまで代表値であり、参考用として提供されています。取引契約の補足として使用されるものではありません。取引においては、別途合意された仕様要件が優先されます。",

    "news.prev": "前の記事へ",
    "news.next": "次の記事へ",
  "popup.content": "「同意する」ボタンをクリックするか、当サイトを引き続きご利用いただくことで、Cookieの使用に同意し、本サイト上での活動に関する個人データの収集および処理を許可するものとします。このような情報は、お客様の訪問体験を最適化するために使用されます。プライバシー規約および情報セキュリティに関する詳細については、こちらのリンクを訪問してご覧ください。",
  "popup.content.btn1": "Cookie政策",
  "popup.content.btn2": "同意する",

  "contact.title": "お問い合わせ",
  "contact.content.title": "紫宸科技上海本社",
  "contact.content.yx": "zcpublic@jxzichen.com",
  "contact.content.dh": "021-61902916",
  "contact.content.dz": "上海市浦東新区秀浦路3188弄創研智造116-117号",
  "esg.responsible": "責任あるサプライチェーン政策",

  ...localeHome,
  ...localeAbout,
  ...localeManufacture,
  ...localeESG,
  ...localeJoinUs,
  ...localePolicy
};
