import localeHome from './zh-CN/home.json';
import localeAbout from './zh-CN/about.json';
import localeManufacture from './zh-CN/manufacture.json';
import localeESG from './zh-CN/ESG.json';
import localeJoinUs from './zh-CN/joinUs.json';
import localePolicy from './zh-CN/policy.json';

export default {
  "screen.tabs.home": "首页",
  "screen.tabs.about": "关于我们",
  "screen.tabs.products": "产品",
  "screen.tabs.news": "新闻资讯",
  "screen.tabs.joinUs": "加入我们",
  "screen.tabs.contact": "联系我们",
  "screen.tabs.manufacturing": "研发智造",

  "screen.btn": "了解更多",

  "screen.footer.product1": "人造石墨负极",
  "screen.footer.product2": "天然石墨负极",
  "screen.footer.product3": "硅基负极",
  "screen.footer.product4": "硬碳负极",

  "screen.footer.manufacturing1": "研究院",
  "screen.footer.manufacturing2": "生产制造",

  "screen.footer.about1": "加入我们",
  "screen.footer.about2": "联系我们",

  "screen.footer.news1": "ESG",
  "screen.footer.bottom1": "隐私政策",
  "screen.footer.bottom2": "法律说明",

  "search.details.null": "没有找到相关内容",
  "search.details.news": "新闻",
  "search.details.product": "产品",
  "search.details.screen": "筛选分类",
  "search.details.filter": "搜索结果",
  "search.text": "请输入搜索内容",
  "product.list.title1": "首次容量",
  "product.list.title2": "首次效率",
  "product.list.title3": "粒径D50",

  "popup.item1": "首次容量",
  "popup.item2": "首次效率",
  "popup.item3": "粒径D50",
  "popup.item4": "压实密度",
  "popup.item5": "快充能力",

  "popup.item6": "本页面展示的物性指标系典型值，仅供参考，不作为交易合同的补充。双方交易时以另行确定的规格要求为准。",
  "news.prev": "上一页",
  "news.next": "下一页",
  "popup.content": "点击“我接受”按钮或进一步使用本网站，即表示同意使用Cookie，并授予我们收集和处理您在本网站上活动的个人数据的权限。此类信息用于优化您的访问体验，有关隐私条款和信息安全的更多信息，请访问此链接阅读更多。",
  "popup.content.btn1": "Cookie政策",
  "popup.content.btn2": "我接受",
  
  "contact.title": "联系我们",
  "contact.content.title": "紫宸科技上海总部",
  "contact.content.yx": "zcpublic@jxzichen.com",
  "contact.content.dh": "021-61902916",
  "contact.content.dz": "上海市浦东新区秀浦路3188弄创研智造116-117号",
  
  "esg.responsible": "负责任的供应链政策",

  // zcpublic@jxzichen.com
  // 021-61902916
  // 上海市浦东新区秀浦路3188弄创研智造116-117

  ...localeHome,
  ...localeAbout,
  ...localeManufacture,
  ...localeESG,
  ...localeJoinUs,
  ...localePolicy
};