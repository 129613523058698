import Vue from 'vue'
import VueRouter from 'vue-router'
// import IndexView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    meta: {
      title: '首页'
    },
    component: () => import('../views/IndexView.vue'),
    children: [
      {
        path: '/',
        redirect: '/index'
      },
      // 首页二级
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页'
        },
        component: () =>
          import("@/pages/IndexView.vue"),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../pages/AboutView.vue'),
        meta: {
          title: '关于我们'
        },
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('../pages/ProductView.vue'),
        meta: {
          title: '产品'
        },
      },
      {
        path: '/ESG',
        name: 'ESG',
        component: () => import('../pages/ESGView.vue'),
        meta: {
          title: 'ESG'
        },
      },
      {
        path: '/manufacture',
        name: 'manufacture',
        component: () => import('../pages/manufactureView.vue'),
        meta: {
          title: '研发与制造'
        },
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../pages/news/newsView.vue'),
        meta: {
          title: '新闻资讯'
        },

      },
      {
        path: '/news/Details/:id',
        name: 'newsDetails',
        component: () => import('../pages/news/DetailsView.vue'),
        meta: {
          title: '新闻资讯详情'
        },
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('../pages/JoinUsView.vue'),
        meta: {
          title: '加入我们'
        },
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../pages/SearchView.vue'),
        meta: {
          title: '搜索详情'
        },
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('../pages/Policy.vue'),
        meta: {
          title: '隐私政策'
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../pages/contact.vue'),
        meta: {
          title: '联系我们'
        },
      },
      {
        path: '/LegalExplanation',
        name: 'LegalExplanation',
        component: () => import('../pages/LegalExplanation.vue'),
        meta: {
          title: '法律声明'
        },
      },
      {
        path: '*',
        name: "NotFound",
        meta: {
          title: '404 Not Found'
        },
        component: () =>
          import("@/views/NotFound.vue")

      }

    ]
  },

]
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, form, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  } else {
      document.title = '紫宸官网' //默认的title
  }
  next()
})

export default router
